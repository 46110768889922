import { Component, OnInit } from '@angular/core';
import { NewsService } from '../../services';

@Component({
  selector: 'news-carousel',
  templateUrl: './news-carousel.component.html',
  styleUrls: ['./news-carousel.component.sass']
})
export class NewsCarouselSectionComponent implements OnInit {

  constructor(private service:NewsService) { }

  headlines;

  ngOnInit(): void {
    this.service.getFeaturedNews().subscribe(
      (response) => {
        this.headlines = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

}
