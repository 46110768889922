import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { HomePageComponent, AboutPageComponent, NewsPageComponent, ArticlePageComponent, EventsPageComponent } from './components/pages';
import { TrusteesService, AuthService, NewsService, SentryErrorHandlingService } from './services';
import { NewsCarouselSectionComponent } from './components/news-carousel/news-carousel.component';
import { MainNavbarComponent } from './components/main-navbar/main-navbar.component';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    AboutPageComponent,
    NewsPageComponent,
    EventsPageComponent,
    ArticlePageComponent,
    MainNavbarComponent,
    NewsCarouselSectionComponent,
    LoginPageComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule
  ],
  providers: [HttpClientModule, TrusteesService, NewsService, AuthService, { provide: ErrorHandler, useClass: SentryErrorHandlingService }],
  bootstrap: [AppComponent]
})
export class AppModule { }
