<main-navbar></main-navbar>

<router-outlet></router-outlet>

<footer class="page-footer">
  <div class="container">
      <div class="row">
          <div class="col-sm-3">
              <h5>Get started</h5>
              <ul>
                  <li><a href="#">Home</a></li>
                  <li *ngIf="!auth.isLoggedIn()"><a routerLink="/login">Log In As Trustee</a></li>
                  <li *ngIf="auth.isLoggedIn()"><a (click)="auth.signOut()">Log Out</a></li>
              </ul>
          </div>
          <div class="col-sm-3">
              <h5>About Us</h5>
              <ul>
                  <li><a href="/about">Club Information</a></li>
                  <li><a href="/assets/policies/constitution.pdf">Constitution</a></li>
                  <li><a href="/news">News</a></li>
              </ul>
          </div>
          <div class="col-sm-3">
              <h5>Get Involved</h5>
              <ul>
                  <li><a href="/events">Events</a></li>
                  <li><a href="/about">Contact Us</a></li>
              </ul>
          </div>
          <div class="col-sm-3">
              <h5>Legal</h5>
              <ul>
                  <li><a href="/assets/policies/simple_privacy.pdf">Simplified Privacy Policy</a></li>
                  <li><a href="/assets/policies/privacy.pdf">Full Privacy Policy</a></li>
              </ul>
          </div>
      </div>
  </div>
  <div class="footer-copyright">
      <p>© 2018 Uttoxeter Skating Club</p>
      <p>Website Designer: Kris Turner</p>
  </div>
</footer>
