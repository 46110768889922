import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  news: any;

  getNews(){
    return this.news;
  }

  getNewsById(articleId: string) {
    return this.store.collection('news').doc(articleId)
    .valueChanges({ idField: 'id' });
  }

  getFeaturedNews(){
    return this.store.collection('news',
      ref => ref.where('featured', '==', true)
      ).valueChanges({ idField: 'id' });
  }

  constructor(private store: AngularFirestore) {
    this.news = this.store.collection('news').valueChanges({ idField: 'id' })
  }


}
