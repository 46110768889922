<main class="page login-page">
  <section class="clean-block clean-form">
    <div class="container">
      <div class="block-heading">
        <h2 class="text-info">Log In</h2>
      </div>

      <form class="block-content">
        <p>The way you login has changed. This is just for administrators so if you're not a trustee your login
          will no longer work.</p>
        
        <div class="alert alert-danger" *ngIf="message && !messageIsSuccess">
          {{ message }}
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input [(ngModel)]="email" name="email" class="form-control item" type="text" id="email" required>
        </div>

        <div class="form-group">
          <label for="password">Password</label>
          <input [(ngModel)]="password" name="password" class="form-control item" type="password" id="password" required>
        </div>

        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="checkbox">
            <label class="form-check-label" for="checkbox">Remember me on this computer</label>
          </div>
        </div>

        <div class="form-group">
          <button class="btn btn-primary btn-block" type="button" (click)="submitForm()">Login</button>
        </div>
      </form>
    </div>
  </section>
</main>
