<ngb-carousel *ngIf="headlines">
	<ng-template ngbSlide *ngFor="let article of headlines">
		<div class="picsum-img-wrapper">
			<img class="news-image" [src]="article.image" alt="{{article.altText}}">
		</div>
		<div class="color-overlay">
			<div class="carousel-caption">
				<h3>{{ article.title }}</h3>
				<p>{{ article.blurb }}</p>
			</div>
		</div>
	</ng-template>
</ngb-carousel>
