import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.sass'],
  // tslint:disable-next-line: no-host-metadata-property
  host: { class:'page-component' }
})
export class EventsPageComponent implements OnInit {

  constructor() { }

  featuredEvents = [];
  events = [];

  ngOnInit(): void {
  }

}
