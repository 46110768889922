import { HomePageComponent,
  AboutPageComponent,
  NewsPageComponent,
  EventsPageComponent,
  LoginPageComponent,
  ArticlePageComponent } from './components/pages';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'about', component: AboutPageComponent },
  { path: 'news', component: NewsPageComponent },
  { path: 'news/:id', component: ArticlePageComponent },
  { path: 'events', component: EventsPageComponent},
  { path: 'login', component: LoginPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
