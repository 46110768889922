import { AuthService } from '../../../services';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.sass']
})
export class LoginPageComponent implements OnInit {

  signInForm: FormGroup;
  email: string;
  password: string;

  messageIsSuccess: boolean;
  message: string;

  async submitForm() {
    try {
      if ( !this.email || !this.password) throw new Error('Make sure you enter your email and password to login.');
      const result = await this.auth.signIn(this.email, this.password);
      if (result) this.router.navigate([ '' ]);
      this.password = "";
      throw new Error('Your email or password was incorrect. Please try again.');
    } catch ( err ) {
      this.message = err;
      this.messageIsSuccess = false;
    }

    }

  constructor( private auth: AuthService, private router: Router ) { }

  ngOnInit(): void {
  }

}
