import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrusteesService {

  trustees$: Observable<any>;

  getTrustees(){
  }

  constructor(private db: AngularFireDatabase) {
    this.trustees$ = db.list("/trustees").valueChanges();
    }

}
