<main class="page">
<section class="clean-block clean-info">
  <div class="container">
      <div class="block-heading">
          <h2>About Us</h2>
          <p class="text-justify">The USC is an independent club which aims to build a strong family atmosphere for the great team of skaters in Uttoxeter.</p>
          <p class="double text-justify">The primary objective of the USC is to support Uttoxeter skaters as an independent body separate to both the rink and the businesses of the coaches. We aim to organise day trips to various attractions (both related and non-related
              to skating) which will help encourage team building and a strong family atmosphere.&nbsp;<br></p>
      </div>
  </div>
</section>
<div class="highlight-phone">
  <div class="container">
      <div class="row">
          <div class="col-md-8">
              <div class="intro">
                  <h2>Our Constitution</h2>
                  <p>Our Constitution is the governing rules of the Uttoxeter Skating Club and is thus very important to us. It sets out the rights and legal of obligations of the Members, Trustees and Volunteers.</p><a class="btn btn-primary"
                      role="button" href="">Read the constitution</a></div>
          </div>
          <div class="col-sm-4">
              <div class="d-none d-md-block iphone-mockup"><img src="/assets/img/iphone.svg" class="device">
                  <div class="screen" style="background-image:url('/assets/img/legal_document.jpg');"></div>
              </div>
          </div>
      </div>
  </div>
</div>
<section class="clean-block clean-info">
  <div class="container">
      <div class="block-heading">
          <div class="row">
              <div class="col">
                  <h6>USC Policies</h6>
                  <ul class="list-unstyled">

                  </ul>
              </div>
              <div class="col">
                  <h6 class="text-info">Meeting Minutes</h6>
                  <ul class="list-unstyled">
                      <li>Coming soon.</li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</section>
</main>
