import { Component, OnInit } from '@angular/core';
import { TrusteesService } from '../../../services';

@Component({
  selector: 'app-home',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.sass'],
  // tslint:disable-next-line: no-host-metadata-property
  host: { class:'page-component' }
})
export class HomePageComponent implements OnInit {

  trustees: any;

  constructor(private trusteesService: TrusteesService) {
  }

  ngOnInit(): void {
    this.trustees = this.trusteesService.trustees$;
  }
}
