<main class="page blog-post-list">
  <section class="clean-block clean-blog-list">
    <div class="container">
      <div class="block-heading">
        <h2 class="text-info">Club News</h2>
        <p>Get up to date with the latest USC news.</p>
      </div>

      <div class="block-content">

        <div *ngIf="(news | async)?.length == 0" class="clean-blog-post">
          <div class="row">
            <p class="no-news">There's no news at the moment! Check back again later.</p>
          </div>
        </div>

        <div *ngFor="let article of news | async" class="clean-blog-post">
          <div class="row">
            <div *ngIf="article.hasListDisplayImage" class="col-lg-5"><img class="rounded img-fluid"
                [src]="article.listImageUrl"></div>
            <div class="col-lg-7">
              <h3>{{ article.title }}</h3>
              <div class="info"><span class="text-muted">{{ article.publishedDate | date }} by <a
                    href="#">{{ article.author }}</a></span></div>
              <p>{{ article.blurb }}</p>
              <a class="btn btn-outline-primary btn-sm" role="button" href="/news/{{ article.id }}">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
