<main class="page event-list">

  <section class="clean-block clean-testimonials">
    <div class="container">
      <div class="block-heading">
        <h2 class="text-info">Club Events</h2>
      </div>

      <div *ngIf="featuredEvents" class="row" style="margin-bottom:20px;">
        <div class="col col-md-12">
          <div></div>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Featured Events</h4>
              <div class="row">
                <div *ngFor="let event of featuredEvents" class="event-item col-md-4">
                  <div class="image"><a href="/events/{{ event.url }}"><img class="img-fluid d-block mx-auto"
                        [src]="event.imageUrl"></a></div>
                  <div class="d-flex justify-content-center event-name"><a href="/events/{{ event.url }}"
                      style="font-size:26px;">{{ event.title }}</a></div>
                  <div class="date">
                    <h6 class="text-center">
                      {{ event.startDate | date:'longDate' }}<br>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col col-md-12">
          <div class="card clean-testimonial-item border-0 rounded-0">
            <div class="card-body">
              <h5 class="card-title">Upcoming Events</h5>

              <div class="category-event row" *ngFor="let event of events">
                <div class="d-flex justify-content-center col-md-4">
                  <img class="event-image" style="background-image:url('{{ event.getImageUrl() }}')">
                </div>
                <div class="col">
                  <h6 class="event-name">{{ event.title }}</h6>
                  <h6 class="event-info event-date">{{ event.startDate | date }}</h6>
                  <a class="btn btn-primary btn-sm col-md-12" role="button" href="/events/{{ event.url }}">Book</a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
