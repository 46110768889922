import { NewsService } from '../../../services';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.sass'],
  // tslint:disable-next-line: no-host-metadata-property
  host: { class:'page-component' }
})
export class NewsPageComponent implements OnInit {

  news: any;

  constructor(private service: NewsService) { }

  ngOnInit(): void {
    this.news = this.service.getNews();  
  }

}
