<main class="page">
  <news-carousel></news-carousel>
  <!-- START Benefit Sections -->
  <section class="benefits clean-block clean-info">
    <div class="container">
      <div class="block-heading">
        <h2>Membership Benefits</h2>
        <p>On the fence whether to join Uttoxeter's skating family? Here's a couple reasons why you should. We'll see
          you
          rinkside.
        </p>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-5 feature-box">
          <h4>Join the Community</h4>
          <p class="text-justify">The primary objective of the Club was to build a strong, friendly community for
            skating in Uttoxeter. We organise both skating and non-skating trips and activities building a strong team
            and family.
          </p>
        </div>
        <div class="col-md-5 feature-box">
          <h4>Access to Equipment</h4>
          <p class="text-justify">The Club constantly invests in new equipment which it makes available to all its
            members. The Club recently
            approved the purchase of a figure skating harness and champion cords.</p>
        </div>
        <div class="col-md-5 feature-box">
          <h4>Enjoy Club Events</h4>
          <p class="text-justify">All members enjoy special club event discounts and exclusive member-only events all
            year round. This includes
            access to all our club general meetings where only members are legally allowed to vote.</p>
        </div>
        <div class="col-md-5 feature-box">
          <h4>Receive Partner Discounts</h4>
          <p class="text-justify">The Club is always working with our local community in order to secure you better
            deals as a club member you can receive these great discounts too.</p>
        </div>
      </div>
    </div>
  </section>
  <!-- END Benefit Sections -->

  <!-- START Award Sections -->
  <section class="clean-block features dark">
    <div class="container">
      <div class="block-heading">
        <h2>Club Awards</h2>
        <p class="text-white">At Uttoxeter Skating Club we know that winning means different things to every one of our
          skaters and we like
          to celebrate their success.</p>
      </div>
      <div class="row align-items-center">
        <div class="col mb-auto">
          <ul class="awards">
            <li><strong>Sean Hallam</strong>: Blackpool 2018 Level 1 Men's (Gold)</li>
            <li><strong>Vicki Smart</strong>: IS&nbsp;Championships 2018 (Two Golds and a Bronze)</li>
            <li><strong>Sam Craddock</strong>: Adult British Prebronze Category (Gold)</li>
            <li><strong>Janet O'Neill</strong>: Adult British Gold Category (Silver)</li>
          </ul>
        </div>
        <div class="col mb-auto">
          <ul class="awards">
            <li><strong>Janet O'Neill</strong>: Bracknall Adults (Gold) 2018 (Bronze)</li>
            <li><strong>Janet O'Neill</strong>: Berchtedgaden Adults (Gold) 2018 (Bronze)</li>
            <li><strong>Sophie McHale</strong>: Adult British Prebronze Category (Silver)</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <!-- END Award Sections -->

  <!-- START Trustees Sections -->
  <section *ngIf="(this.trustees | async)?.length > 0" class="clean-block trustees-section">
    <div class="container">
      <div class="block-heading">
        <h2>Meet the Trustees</h2>
        <p>Uttoxeter Skating Club's board of Trustees is made up of a team of wonderful individuals who volunteer their
          time to keep the club going.<br></p>
      </div>
      <div class="row justify-content-center trustee-row">

        <div *ngFor="let trustee of trustees | async" class="col-sm-6 col-lg-4">
          <div class="card clean-card text-center">
            <img class="card-img-top w-100 d-block" style="height:288;" [src]="trustee.imageUrl">
            <div class="card-body info">
              <h4 class="card-title">{{ trustee.name }}</h4>
              <p class="card-info">{{ trustee.role }}</p>
              <div class="icons">
                <a href="mailto:{{ trustee.email }}"><i class="icon-envelope"></i></a>
                <a *ngIf="trustee.facebook" [href]="trustee.facebook"><i class="icon-social-facebook"></i></a>
                <a *ngIf="trustee.instagram" [href]="trustee.instagram"><i class="icon-social-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- END Trustees Sections -->

</main>
