import { AuthService } from './services/auth.service';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

  public auth: AuthService;

  constructor(service: AuthService, private store: AngularFirestore) {
    this.auth = service;
  }

  ngOnInit() {

  }


}
