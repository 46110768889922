<nav class="navbar navbar-light navbar-expand-md navigation-clean-button">
	<div class="container-fluid">
		<a class="navbar-brand d-flex" href="#">
			<div><img id="headerLogo" src="assets/img/USC.png"></div>
		</a><button data-toggle="collapse" class="navbar-toggler" data-target="#navcol-1"><span class="sr-only">Toggle navigation</span><span class="navbar-toggler-icon"></span></button>
		<div class="collapse navbar-collapse" id="navcol-1">
			<ul class="nav navbar-nav ml-auto">
				<li class="nav-item" role="presentation"><a class="nav-link" routerLink="/">Home</a></li>
				<li class="nav-item" role="presentation"><a class="nav-link" routerLink="/about">About</a></li>
				<li class="nav-item" role="presentation"><a class="nav-link" routerLink="/news">News</a></li>
				<li class="nav-item" role="presentation"><a class="nav-link" routerLink="/events">Events</a></li>
			</ul>
		</div>
	</div>
</nav>
