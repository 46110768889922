<main class="page blog-post">
  <section class="clean-block clean-post">
      <div class="container">
          <div class="block-content">
              <div *ngIf="article.headerImageUrl "class="post-image" style="background-image:url('{{ article.headerImageUrl }}')"></div>
              <div class="post-body">
                  <h3>{{ (article | async)?.title }}</h3>
                  <div class="post-info">By {{ (article | async)?.author }} | {{ ( article | async)?.publishedDate.toDate() | date:'longDate' }}
                  </div>

                  {{ (article | async)?.content }}

              </div>
          </div>
      </div>
  </section>
 </main>
