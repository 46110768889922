import { NewsService } from '../../../services';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-page',
  templateUrl: './article-page.component.html',
  styleUrls: ['./article-page.component.sass'],
   // tslint:disable-next-line: no-host-metadata-property
   host: { class:'page-component' }
})
export class ArticlePageComponent implements OnInit {
  article: any;

  constructor(private service: NewsService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const articleId: string = this.route.snapshot.paramMap.get('id');
    this.article = this.service.getNewsById(articleId);
    console.log(articleId)
  }
}
