import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public signedIn: Observable<any>;
  public user;

  constructor(public auth: AngularFireAuth) {
      this.auth.onAuthStateChanged((user) => {
        this.user = user;
      });
  }

  
  isLoggedIn() { 
    return !(this.user === null);
  }

  public async signIn(email: string, password: string) {
      try {
          if (!email || !password) throw new Error('Invalid email and/or password');
          await this.auth.signInWithEmailAndPassword(email, password);
          return true;
      } catch (error) {
          return false;
      }
  }

  public async signOut() {
      try {
        console.log(this.user);
          await this.auth.signOut();
          console.log(this.user);
          return true;
      } catch (error) {
          console.log('Sign out failed', error);
          return false;
      }
  }

}
